import { required, numeric } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addGroup',
  data() {
    return {
      parent_value_set_id: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      groupListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      groupForm: {
        group_id: null,
        group_name: null,
        approval_count: null,
        group_type_vset: null,
        group_type: null,
        group_details: [
          {
            group_line_id: null,
            optional_flag: false,
            group_type: null,
            value_code: null,
            value_meaning: null,
            value_set_dtl_id: null
          }
        ]
      },
      groupDetailFeilds: [
        {
          key: 'value_meaning',
          label: 'Position'
        },
        {
          key: 'optional_flag',
          label: 'Optional'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    groupForm: {
      group_name: {
        required
      },
      group_type: {
        required
      },
      approval_count: { required, numeric }
    }
  },
  mounted() {
    this.eventBus.$on('groupDetail', groupDetail => {
      this.getGroupDetail(groupDetail.group_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditGroup();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.groupForm.group_id });
        }
      }
    });
  },
  methods: {
    addEditGroup() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const groupDetail = this.groupForm.group_details.map(detatil => {
          return {
            group_line_id: detatil.group_line_id,
            optional_flag: detatil.optional_flag,
            value_code: detatil.value_code
          };
        });
        const payload = {
          approval_count: this.groupForm.approval_count,
          group_details: groupDetail,
          group_id: this.groupForm.group_id,
          group_name: this.groupForm.group_name,
          group_type_vset: this.groupForm.group_type_vset
        };
        this.loader = true;
        this.$store
          .dispatch('workflow/addEditGroup', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getGroupDetail(groupHdrId) {
      this.loader = true;
      this.$store
        .dispatch('workflow/getGroupDetail', groupHdrId)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (response.status === 200) {
            this.groupForm = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.POSITION) {
        this.groupForm.group_details[this.groupListIndex].value_meaning =
          item.value_meaning;
        this.groupForm.group_details[this.groupListIndex].value_code =
          item.value_code;
      } else {
        this.groupForm.group_type = item.value_meaning;
        this.groupForm.group_type_vset = item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      if (index === null) {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.groupListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.groupForm.group_details.push({
          group_line_id: null,
          optional_flag: false,
          group_type: null,
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        });
      }
    },
    removeRow(index) {
      this.groupForm.group_details.splice(index, 1);
    },
    rowSelected() {},
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'POSITION') {
        this.groupForm.group_details[index].value_meaning = null;
        this.groupForm.group_details[index].value_code = null;
      } else {
        this.groupForm.group_type = null;
        this.groupForm.group_type_vset = null;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('groupDetail');
    this.unsubscribe();
  }
};
