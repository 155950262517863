import commonHelper from '@/app/utility/common.helper.utility';
import addGroup from './addGroup';
export default {
  name: 'workFlowGroup',
  components: { addGroup },
  props: { showMasterGroupModal: Boolean },
  watch: {
    currentPage: function() {
      this.getGroupList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGroupList();
    }
  },
  data() {
    return {
      timeOut: null,
      showAddGroup: false,
      unsubscribe: null,
      groupName: null,
      loader: false,
      payload: {},
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      groupList: [],
      groupFeilds: [
        {
          key: 'group_name'
        },
        {
          key: 'approval_count'
        },
        {
          key: 'group_type'
        }
      ]
    };
  },
  mounted() {
    this.getGroupList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddGroup = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'workflow/getGoupsList',
            'workflow-group',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getGroupList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        group_name: this.groupName
      };
      this.$store
        .dispatch('workflow/getGoupsList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.groupList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.groupName = null;
      this.getGroupList();
    },
    hideGroupModal() {
      this.showAddGroup = false;
    },
    rowSelected(item) {
      if (this.showMasterGroupModal) {
        this.$emit('selectedGroup', item);
      } else {
        this.showAddGroup = true;
        this.timeOut = setTimeout(() => {
          this.eventBus.$emit('groupDetail', item);
        }, 0);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    this.unsubscribe();
  }
};
